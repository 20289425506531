/**
 * 活动管理-活动信息-编辑页
 * luxinwen
 * 2023-03-22
 */
<style scoped lang="less">
  @import "./edit.less";
</style>

<template>
  <div>
    <Drawer class="drawer" :title="title" v-model="myValue" width="900" :before-close="beforeClose">
      <div class="step">
        <Steps :current="currentStep">
          <Step title="活动属性"></Step>
          <Step title="活动信息"></Step>
        </Steps>
      </div>
      <Form ref="editForm" :model="formData" :rules="formRule" :label-width="120">
        <div v-show="currentStep === 0">
          <div class="flex">
            <div class="flex-item">
              <div class="flex-item-thead">请选择活动省份</div>
              <div class="flex-item-tbody">
                <p v-for="(item, index) in provinceList" :key="'prov' + index" :class="{'active': item.value === formData.provinceId}" @click="changeProvince(item)">{{ item.label }}</p>
              </div>
            </div>
            <div class="flex-item">
              <div class="flex-item-thead">请选择活动城市</div>
              <div class="flex-item-tbody">
                <p v-for="(item, index) in cityList" :key="'city' + index" :class="{'active': item.value === formData.cityId}" @click="changeCity(item)">{{ item.label }}</p>
              </div>
            </div>
            <div class="flex-item">
              <div class="flex-item-thead">请选择活动分类</div>
              <div class="flex-item-tbody">
                <p v-for="(value, key) in typeList" :key="'type' + key" :class="{'active': key === formData.activityType}" @click="changeType(key)">{{ value }}</p>
              </div>
            </div>
          </div>
        </div>
        <div v-show="currentStep === 1">
          <div class="page-main-content-thead">活动信息</div>
          <FormItem label="活动名称" prop="activityName">
            <Input v-model.trim="formData.activityName" class="width-l" maxlength="50" clearable :disabled="isShow" />
          </FormItem>
          <FormItem label="活动主图" prop="activityMainPic">
            <sp-upload v-model="formData.activityMainPic" :img-width="1080" :img-height="720" :disabled="isShow"></sp-upload>
            <p class="text-tips">请上传宽高为1080*720的图片</p>
          </FormItem>
          <FormItem label="活动时间" prop="activityTime">
            <DatePicker class="width-m" v-model="formData.activityTime" type="datetimerange" :disabled="isShow" />
          </FormItem>
          <div class="page-main-content-thead">活动简介</div>
          <FormItem :label-width="0">
            <sp-editor v-model="formData.activityContent" :disabled="isShow"></sp-editor>
          </FormItem>
          <div class="page-main-content-thead">活动规则</div>
          <FormItem :label-width="0">
            <sp-editor v-model="formData.activityRules" :disabled="isShow"></sp-editor>
          </FormItem>
        </div>
      </Form>
      <div class="drawer-foot">
        <Button type="info" :disabled="currentStep === 0" @click="goPrev">上一步</Button>
        <Button type="primary" :disabled="currentStep >= 1 && isShow" @click="submit">{{ currentStep >= 1 ? '提交' : '下一步' }}</Button>
      </div>
      <Spin size="large" fix v-if="isLoading"></Spin>
    </Drawer>
  </div>
</template>

<script>
  import { ACTIVITY_TYPE } from '@/util/enum';
  const formData = {
    activityCity: '', // 活动城市
    activityContent: '',  // 活动内容
    activityMainPic: '',  // 活动主图
    activityName: '', // 活动名称
    activityRules: '',  // 活动规则
    activityType: '', // 活动类型，QMYX全民营销,LDXHD老带新活动
    buildingId: null, // 项目楼盘id
    startDate: '',  // 开始时间
    endDate: '',  // 结束时间
    provinceId: null, // 省份id
    cityId: null, // 城市id
    townId: null, // 区域id
    status: null,  // 活动状态:0下架1上架
    activityTime: []
  };

  export default {
    props: {
      value: {
        type: Boolean,
        default: false
      },
      data: {
        type: Object,
        default() {
          return {};
        }
      },
      flag: {
        type: String
      }
    },
    data() {
      return {
        myValue: this.value,  // 是否显示抽屉
        isInit: false,        // 是否初始化
        isLoading: false,     // 是否加载中
        formData: Object.assign({}, formData),
        formRule: {
          activityName: [
            {
              required: true,
              message: '请输入活动名称',
              trigger: 'blur'
            }
          ],
          activityMainPic: [
            {
              required: true,
              message: '请上传活动主图',
              trigger: 'change'
            }
          ],
          activityTime: [
            {
              required: true,
              trigger: 'change',
              validator: (rule, value, callback) => {
                if (value && value.length > 0 && value.every(item => item)) {
                  callback();
                } else {
                  callback(new Error('请选择活动时间'));
                }
              }
            }
          ]
        },
        backupData: {},
        currentStep: 0,
        provinceList: [],
        cityList: [],
        typeList: ACTIVITY_TYPE
      };
    },
    computed: {
      title() {
        return {
          'add': '新增',
          'show': '查看',
          'edit': '编辑'
        }[this.flag] + '活动';
      },
      isEqual() {
        return this.deepEqual(this.formData, this.backupData);
      },
      isShow() {
        return this.flag === 'show';
      },
      isEdit() {
        return this.flag === 'edit';
      }
    },
    methods: {
      /**
       * 初始化数据
       */
      initData(data = {}, isInit = false) {
        this.formData = this.deepCopy(formData);
        Object.keys(this.formData).forEach(item => {
          if (data.hasOwnProperty(item)) {
            this.formData[item] = data[item];
          }
        });
        this.formData.activityTime = [this.formData.startDate, this.formData.endDate];
        if (isInit) {
          this.$nextTick(() => {
            this.backupData = this.deepCopy(this.formData);
            this.isInit = true;
          });
        }
      },
      /**
       * 获取数据
       */
      getData() {
        this.getProvinceRegionList();
        this.cityList = [];
        this.currentStep = 0;
        if (this.data.provinceId) {
          this.getCityList(this.data.provinceId);
        }
        this.initData(this.data, true);
      },
      /**
       * 关闭前的回调
       */
      beforeClose() {
        return new Promise((resolve, reject) => {
          if (!this.isInit) {
            // 如果未初始化时快速点击关闭，则不做处理
          } else if (this.isEqual) {
            this.myValue = false;
          } else {
            this.$confirm('数据变更后未保存，是否直接关闭？').then(() => {
              this.myValue = false;
            });
          }
        });
      },
      /**
       * 提交
       */
      submit() {
        if (this.currentStep === 0) {
          if (this.formData.cityId === null) {
            return this.notice('请选择项目城市');
          }
          // if (this.formData.category === null) {
          //   return this.notice('请选择项目类型');
          // }
          this.currentStep++;
        } else if (this.currentStep === 1) {
          this.$refs.editForm.validate(valid => {
            if (valid) {
              this.$confirm('确定要提交吗？').then(() => {
                let url = this.$api.activity.addActivity;
                let data = Object.assign({}, this.formData);
                data.startDate = this.formatDate(data.activityTime[0]);
                data.endDate = this.formatDate(data.activityTime[1]);
                delete data.activityTime;
                if (this.isEdit) {
                  url = this.$api.activity.updateActivity;
                  data.id = this.data.id;
                }
                this.save(url, data);
              });
            }
          });
        }
      },
      /**
       * 保存
       */
      save(url, data) {
        this.$axios({
          url,
          data,
          beforeSend: () => {
            this.isLoading = true;
          },
          complete: () => {
            this.isLoading = false;
          }
        }).then(data => {
          this.notice({
            type: 'success',
            desc: '提交成功'
          });
          this.myValue = false;
          this.$emit('success');
        });
      },
      /**
       * 返回上一步
       */
      goPrev() {
        this.currentStep--;
      },
      /**
       * 查询省份列表
       */
      getProvinceRegionList() {
        if (this.provinceList.length > 0) return;
        this.$axios({
          url: this.$api.region.queryProvinceRegionList,
          data: {
            serviceType: 1
          }
        }).then(data => {
          (data || []).forEach(item => {
            this.provinceList.push({
              value: item.regionId,
              label: item.regionName
            });
          });
        });
      },
      /**
       * 获取城市区域列表
       */
      getCityList(parentId, type = 'city') {
        let serviceType = 1;
        let parent = this.cityList;
        // if (type === 'town') {
        //   serviceType = 0;
        //   parent = this.townList;
        // }
        this.$axios({
          url: this.$api.region.queryCityRegionList,
          data: {
            regionParentId: parentId,
            serviceType
          }
        }).then(data => {
          (data || []).forEach(item => {
            parent.push({
              value: item.regionId,
              label: item.regionName
            });
          });
        });
      },
      /**
       * 选择项目省份
       */
      changeProvince(item) {
        if (this.isShow) return;
        if (item.value === this.formData.provinceId) return;
        this.formData.provinceId = item.value;
        this.formData.cityId = null;
        this.cityList = [];
        this.getCityList(item.value);
      },
      /**
       * 选择项目城市
       */
      changeCity(item) {
        if (this.isShow) return;
        this.formData.cityId = item.value;
        this.formData.activityCity = item.label;
      },
      /**
       * 选择项目类型
       */
      changeType(value) {
        if (this.isShow) return;
        this.formData.activityType = value;
      }
    },
    watch: {
      value(val) {
        this.myValue = val;
        if (this.myValue) {
          // 获取数据
          this.getData();
          // 隐藏页面滚动条
          document.querySelector('body').classList.add('hidden');
          // 重置内容域滚动条
          this.$nextTick(() => {
            let $drawer = document.querySelector('.drawer .ivu-drawer-body');
            if ($drawer) $drawer.scrollTo(0, 0);
          });
        } else {
          // 清除表单验证效果
          this.$refs.editForm && this.$refs.editForm.resetFields();
          // 重置初始化状态
          this.isInit = false;
          // 恢复页面滚动条
          document.querySelector('body').classList.remove('hidden');
        }
      },
      myValue(val) {
        this.$emit('input', val);
      }
    }
  };
</script>