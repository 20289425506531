/**
 * 活动管理-活动信息
 * luxinwen
 * 2023-03-22
 */
<template>
  <div class="page">
    <div class="page-main">
      <div class="top-form" v-grant="'OSP_ACTIVITY_MGM_QUERY'">
        <Form :model="formData" inline>
          <FormItem>
            <Input class="width-s" v-model="formData.id" placeholder="请输入活动ID" clearable />
          </FormItem>
          <FormItem>
            <Input class="width-s" v-model="formData.activityName" placeholder="请输入活动名称" clearable />
          </FormItem>
          <FormItem>
            <Cascader v-model="formData.citys" :data="provinceList" :load-data="loadCity" placeholder="请选择活动城市" clearable></Cascader>
          </FormItem>
          <FormItem>
            <Select class="width-s" v-model="formData.activityType" placeholder="请选择活动分类" clearable>
              <Option v-for="(value, key) in typeList" :key="'type' + key" :value="key">{{ value }}</Option>
            </Select>
          </FormItem>
          <FormItem>
            <Select class="width-s" v-model="formData.progressStatus" placeholder="请选择活动进行状态" clearable>
              <Option v-for="(value, key) in progressStatusList" :key="'progressStatus' + key" :value="key">{{ value }}</Option>
            </Select>
          </FormItem>
          <FormItem>
            <Select class="width-s" v-model="formData.status" placeholder="请选择活动状态" clearable>
              <Option v-for="(value, key) in statusList" :key="'status' + key" :value="key">{{ value }}</Option>
            </Select>
          </FormItem>
          <FormItem>
            <DatePicker type="date" v-model="formData.targetDate" placeholder="请选择活动日期" class="width-s"></DatePicker>
          </FormItem>
          <FormItem>
            <Button type="primary" @click="queryData">查询</Button>
          </FormItem>
        </Form>
      </div>
      <div class="page-main-content" v-grant="'OSP_ACTIVITY_MGM_SAVE'">
        <Button type="primary" @click="addData">新增活动</Button>
      </div>
      <div class="page-main-content">
        <sp-table :columns="filterColumns" :data="tableData" :loading="tableLoading" :total="pager.total" :page="pager.pageNum" :page-size="pager.pageSize" @change="changePage">
          <template slot-scope="params" slot="pic">
            <img :src="params.row.activityMainPic" class="img-preview" @click="previewImage(params.row.activityMainPic)">
          </template>
          <template slot-scope="params" slot="type">
            <span>{{ typeList[params.row.activityType] }}</span>
          </template>
          <template slot-scope="params" slot="time">
            <span>{{ formatDate(params.row.startDate, 'yyyy-MM-dd') + ' - ' + formatDate(params.row.endDate, 'yyyy-MM-dd') }}</span>
          </template>
          <template slot-scope="params" slot="progressStatus">
            <span>{{ progressStatusList[params.row.progressStatus] }}</span>
          </template>
          <template slot-scope="params" slot="status">
            <Tag :color="params.row.status === 0 ? 'error' : 'success'">{{ statusList[params.row.status] }}</Tag>
          </template>
          <template slot-scope="params" slot="operation">
            <Button @click="editData(params.row, true)" v-grant="'OSP_ACTIVITY_MGM_VIEW'">查看</Button>
            <Button @click="editData(params.row, false)" v-grant="'OSP_ACTIVITY_MGM_UPDATE'">编辑</Button>
            <Button @click="showItem(params.row)" v-grant="'OSP_ACTIVITY_ITEM'">关联项目</Button>
            <Button @click="showInfo(params.row)" v-grant="'OSP_ACTIVITY_INFO'">参与信息</Button>
            <Button :type="params.row.status === 0 ? 'default' : 'error'" @click="changeStatus(params.row)" v-grant="'OSP_ACTIVITY_MGM_CHANGE'">{{ params.row.status === 0 ? '启用' : '禁用' }}</Button>
            <Button type="error" @click="removeData(params.row)" v-grant="'OSP_ACTIVITY_MGM_DELETE'">删除</Button>
          </template>
        </sp-table>
      </div>
    </div>

    <edit-drawer v-model="drawerDisplay" :data="drawerData" :flag="drawerFlag" @success="getData"></edit-drawer>
    <modal-preview v-model="previewImageUrl"></modal-preview>
  </div>
</template>

<script>
  import { ACTIVITY_TYPE, ACTIVITY_STATUS, ACTIVITY_PROGRESS_STATUS } from '@/util/enum';
  import editDrawer from './edit.vue';

  export default {
    components: {
      editDrawer
    },
    data() {
      return {
        formData: {
          activityName: '', // 活动名称
          activityType: '', // 活动类型
          id: '',
          progressStatus: null, // 活动进行状态：0未开始，1进行中，2已结束
          status: null, // 活动状态:0下架1上架
          citys: [],
          targetDate: null
        },
        tableColumns: [
          {
            title: '活动ID',
            key: 'id'
          },
          {
            title: '活动主图',
            width: 220,
            slot: 'pic'
          },
          {
            title: '活动名称',
            key: 'activityName'
          },
          {
            title: '活动城市',
            key: 'activityCity'
          },
          {
            title: '活动分类',
            slot: 'type'
          },
          {
            title: '活动时间',
            slot: 'time'
          },
          {
            title: '活动进行状态',
            slot: 'progressStatus'
          },
          {
            title: '活动状态',
            slot: 'status'
          },
          {
            title: '操作',
            slot: 'operation',
            align: 'center'
          }
        ],
        tableData: [],
        tableLoading: false, // 表格查询状态
        pager: {
          total: 0,     // 总条数
          pageNum: 1,   // 当前页码
          pageSize: 10  // 每页条数
        },
        drawerDisplay: false, // 是否显示编辑抽屉
        drawerData: {},       // 编辑抽屉数据
        drawerFlag: 'add',    // 编辑抽屉状态
        provinceList: [],
        typeList: ACTIVITY_TYPE,
        statusList: ACTIVITY_STATUS,
        progressStatusList: ACTIVITY_PROGRESS_STATUS,
        previewImageUrl: ''
      };
    },
    computed: {
      filterColumns() {
        let val = [];
        let grant = [this.$grant.OSP_ACTIVITY_MGM_VIEW, this.$grant.OSP_ACTIVITY_MGM_UPDATE, this.$grant.OSP_ACTIVITY_ITEM, this.$grant.OSP_ACTIVITY_INFO, this.$grant.OSP_ACTIVITY_MGM_CHANGE, this.$grant.OSP_ACTIVITY_MGM_DELETE];
        if (!this.checkGrant(grant)) {
          val = this.tableColumns.filter(item => item.slot !== 'operation');
        } else {
          val = this.tableColumns;
        }
        return val;
      }
    },
    created() {
      this.getData();
      this.getProvinceRegionList();
    },
    methods: {
      /**
       * 获取数据
       */
      getData() {
        if (this.formData.targetDate) {
          this.formData.targetDate = this.formatDate(this.formData.targetDate, 'yyyy-MM-dd');
        }
        let data = Object.assign({}, this.formData, {
          pageNum: this.pager.pageNum,
          pageSize: this.pager.pageSize
        });
        if (data.citys.length > 1) {
          data.provinceId = data.citys[0];
          data.cityId = data.citys[1];
        }
        delete data.citys;
        this.$axios({
          url: this.$api.activity.queryActivityList,
          data,
          beforeSend: () => {
            this.tableLoading = true;
          },
          complete: () => {
            this.tableLoading = false;
          }
        }).then(data => {
          this.tableData = data.list || [];
          this.pager.total = data.total || 0;
        });
      },
      /**
       * 查询省份列表
       */
      getProvinceRegionList() {
        this.$axios({
          url: this.$api.region.queryProvinceRegionList,
          data: {
            serviceType: 1
          }
        }).then(data => {
          (data || []).forEach(item => {
            this.provinceList.push({
              value: item.regionId,
              label: item.regionName,
              children: [],
              loading: false
            });
          });
        });
      },
      /**
       * 获取城市区域列表
       */
      loadCity(item, callback) {
        this.$axios({
          url: this.$api.region.queryCityRegionList,
          data: {
            regionParentId: item.value,
            serviceType: 1
          },
          beforeSend: () => {
            item.loading = true;
          },
          complete: () => {
            item.loading = false;
          }
        }).then(data => {
          (data || []).forEach(city => {
            item.children.push({
              value: city.regionId,
              label: city.regionName
            });
          });
          callback && callback();
        });
      },
      /**
       * 查询数据
       */
      queryData() {
        this.pager.pageNum = 1;
        this.getData();
      },
      /**
       * 新增数据
       */
      addData() {
        this.drawerFlag = 'add';
        this.drawerData = {};
        this.drawerDisplay = true;
      },
      /**
       * 查看、编辑
       */
      editData(row, onlyRoad = false) {
        this.drawerFlag = onlyRoad ? 'show' : 'edit';
        this.drawerData = row;
        this.drawerDisplay = true;
      },
      /**
       * 关联项目
       */
      showItem(row) {
        this.$router.push({
          name: 'activityMgmItem',
          query: {
            id: row.id,
            cityId: row.cityId
          }
        });
      },
      /**
       * 参与信息
       */
      showInfo(row) {
        this.$router.push({
          name: 'activityMgmInfo',
          query: {
            id: row.id
          }
        });
      },
      /**
       * 禁用、启用
       */
      changeStatus(row) {
        let txt = row.status === 1 ? '禁用' : '启用';
        let status = row.status === 1 ? 0 : 1;
        this.$confirm(`确定要将 [${row.activityName}] 设置为${txt}吗？`).then(() => {
          this.$axios({
            url: this.$api.activity.updateActivity,
            data: {
              id: row.id,
              status
            },
            loading: true
          }).then(data => {
            this.notice({
              type: 'success',
              desc: txt + '成功'
            });
            this.getData();
          });
        });
      },
      /**
       * 删除数据
       */
      removeData(row) {
        this.$confirm(`确定要删除 [${row.activityName}] 吗？`).then(() => {
          this.$axios({
            url: this.$api.activity.deleteActivity,
            data: {
              id: row.id
            },
            loading: true
          }).then(data => {
            this.notice({
              type: 'success',
              desc: '删除成功'
            });
            this.getData();
          });
        });
      },
      /**
       * 预览
       */
      previewImage(url) {
        this.previewImageUrl = url;
      },
      /**
       * 切换页码或每条页数
       */
      changePage(pager) {
        this.pager.pageNum = pager.page;
        this.pager.pageSize = pager.pageSize;
        this.getData();
      }
    }
  };
</script>